import { FC, useEffect, useState } from 'react';
import { useTranslation } from '@packages/utils';
import { useEditSiteCoordinatorMutation } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TOption, TRtkErrorType } from 'data/types/generic.types';
import { Select } from 'antd/lib';
import { TWorkshopDetailsDataUI } from 'data/types/workshops.types';
import Spinner from 'components/Base/Spinner';
import { Flex } from 'antd';
import { TCreateCoordinatorModalOpen } from '../CreateCoordinatorModal/CreateCoordinatorModal.types';
import CoordinatorTag from './CoordinatorTag';
import CoordinatorDropdown from './CoordinatorDropdown';

type CoordinatorSelectorProps = {
  id: string;
  coordinators?: TWorkshopDetailsDataUI['siteCoordinators'];
  options?: TOption[];
  selectedCoordinators: any[];
  setSelectedCoordinators: React.Dispatch<React.SetStateAction<any[]>>;
  setOpen: React.Dispatch<React.SetStateAction<TCreateCoordinatorModalOpen>>;
  isLoading?: boolean;
  onBlur?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CoordinatorTagRender = (props: any) => {
  return <CoordinatorTag {...props} />;
};
const CoordinatorDropdownRender = (props: any) => (
  <CoordinatorDropdown {...props} />
);

const CoordinatorSelector: FC<CoordinatorSelectorProps> = ({
  id,
  coordinators,
  options,
  selectedCoordinators,
  setSelectedCoordinators,
  setOpen,
  isLoading,
  onBlur,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.coordinator',
  });
  const { showToastNotification } = useShowToastNotification();

  const [initialName, setInitialName] = useState<string>('');

  useEffect(() => {
    const selected = coordinators?.map(coordinator =>
      options?.find(person => person.value === coordinator.id),
    );

    setSelectedCoordinators(selected as TOption[]);
  }, [coordinators, options]);

  const [editSiteCoordinator] = useEditSiteCoordinatorMutation();

  const onSearch = (value: string) => {
    setInitialName(value);
  };

  const onEdit = async (values: string[]) => {
    if (values.length > 3) {
      showToastNotification({
        message: 'You can only select up to 3 coordinators',
        type: ENotificationType.INFO,
      });

      return;
    }

    if (!values.length) {
      showToastNotification({
        message: 'There should at least be 1 coordinator left.',
        type: ENotificationType.INFO,
      });

      return;
    }

    const updatedSelectedCoordinators = values.map(value =>
      options?.find(person => person.value === value),
    );

    setSelectedCoordinators(updatedSelectedCoordinators);

    const params = {
      id,
      value: values.map(value => value),
    };

    try {
      await editSiteCoordinator(params).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  if (isLoading)
    return (
      <Flex align="center" style={{ minHeight: 32 }}>
        <Spinner />
      </Flex>
    );

  return (
    <Select
      mode="multiple"
      autoFocus
      showSearch
      onBlur={() => onBlur && onBlur(false)}
      onClick={e => {
        // this is a workaround so that the close event when you click on the add button will not clear the input field.
        if (
          e.currentTarget.tagName === 'DIV' &&
          e.currentTarget.className.includes('ant-select')
        ) {
          e.stopPropagation();
        }
      }}
      onChange={onEdit}
      onSearch={onSearch}
      value={selectedCoordinators?.map(coordinator => coordinator?.value)}
      options={options}
      filterOption={(input, option) =>
        option
          ? option.label.toLowerCase().includes(input.toLowerCase())
          : false
      }
      placeholder={t('EMPTY_TEXT')}
      tagRender={props =>
        CoordinatorTagRender({
          ...props,
          closable: selectedCoordinators?.length > 1,
          coordinators,
          setOpen,
        })
      }
      dropdownRender={menu =>
        CoordinatorDropdownRender({
          menu,
          initialName,
          setOpen,
          disabled: selectedCoordinators?.length === 3,
        })
      }
      style={{ minWidth: '50%', zIndex: 100 }}
    />
  );
};

export default CoordinatorSelector;
