import { FC } from 'react';
import { EWorkshopDetailsAllowedActions } from 'data/types/workshops.types';
import { Col, Row } from 'components/Base/Grid';
import { TActionsGroupProps } from './Actions.types';
import PublishAction from './PublishAction';
import ActionsGroupButton from './ActionsGroupButton';
import RescheduleAction from './RescheduleAction';
import RepublishAction from './RepublishAction';

const Actions: FC<TActionsGroupProps> = ({ id, data }) => {
  // if there are no site coordinators, the publish button should be disabled
  const noSiteCoordinators = data?.siteCoordinators?.length === 0;

  return (
    <Row gutter={[16, 16]}>
      {data?.allowedActions?.includes(
        EWorkshopDetailsAllowedActions.PUBLISH,
      ) && (
        <Col>
          <PublishAction id={id} disabled={noSiteCoordinators} />
        </Col>
      )}
      {data?.allowedActions?.includes(
        EWorkshopDetailsAllowedActions.RESCHEDULE,
      ) && (
        <Col>
          <RescheduleAction id={id} />
        </Col>
      )}
      {data?.allowedActions?.includes(
        EWorkshopDetailsAllowedActions.REPUBLISH,
      ) && (
        <Col>
          <RepublishAction id={id} />
        </Col>
      )}
      <Col>
        <ActionsGroupButton id={id} data={data} />
      </Col>
    </Row>
  );
};

export default Actions;
