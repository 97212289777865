import { ENotificationType, TMenuProps } from '@idaho-aeyc/ui-kit';
import ActionsButtonDetailsPage from 'components/Shared/ActionsButtonDetailsPage';
import { getFormattedDateMDY } from 'utils/dateUtils';
import { downloadCSV } from 'helpers/downloadCsv';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import { useLazyExportWaitlistCSVQuery } from 'redux/apiSlice/registrants.slice';
import { TRtkErrorType, useShowToastNotification } from '@packages/utils';
import {
  EWaitlistActionsActions,
  TWaitlistActionsProps,
} from './WaitlistActions.types';

const WaitListActions: React.FC<TWaitlistActionsProps> = ({
  selectedRows,
  setShowAlert,
}) => {
  const { showToastNotification } = useShowToastNotification();

  const [exportParents] = useLazyExportWaitlistCSVQuery();

  const handleExportAll = async () => {
    try {
      await exportParents().unwrap();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  if (!selectedRows) return null;

  const exportSelectedRows = () => {
    const { selectedRowsRecords } = selectedRows;

    if (!selectedRowsRecords.length) {
      setShowAlert(true);
      return;
    }

    const selectedRowsTransformed = selectedRowsRecords.map(row => {
      if (row === undefined) return undefined;

      const {
        createdDate,
        location,
        city,
        workshopType,
        englishAgeGroups,
        spanishAgeGroups,
        firstName,
        lastName,
        email,
        phone,
        tags,
      } = row;

      const ageGroup = [
        ...englishAgeGroups.map(eg => eg.ageGroup),
        ...spanishAgeGroups.map(sg => sg.ageGroup),
      ].join(', ');

      return {
        createdDate,
        location,
        city,
        ageGroup,
        workshopType,
        firstName,
        lastName,
        email,
        phone,
        tags: tags?.map(tag => tag.text).join(', '),
      };
    });

    const formattedDate = getFormattedDateMDY();

    downloadCSV(
      [
        'Registered Date',
        'Location',
        'City',
        'Age Group',
        'Workshop Type',
        'First Name',
        'Last Name',
        'Email',
        'Mobile',
        'Tags',
      ],
      selectedRowsTransformed,
      record => [
        `"${record.createdDate}"`,
        `"${record.location}"`,
        `"${record.city}"`,
        `"${record.ageGroup}"`,
        `"${record.workshopType}"`,
        `"${record.firstName}"`,
        `"${record.lastName}"`,
        `"${record.email}"`,
        `"${record.phone}"`,
        `"${record.tags}"`,
      ],
      `Subscribers_WaitList_${formattedDate}.csv`,
    );
  };

  const items: TMenuProps['items'] = [
    {
      label: (
        <ActionGroupItemLabel
          onClick={exportSelectedRows}
          label="Export Selected"
        />
      ),
      key: EWaitlistActionsActions.EXPORT,
    },
    {
      label: (
        <ActionGroupItemLabel onClick={handleExportAll} label="Export All" />
      ),
      key: EWaitlistActionsActions.EXPORT_ALL,
    },
  ];

  return <ActionsButtonDetailsPage items={items} />;
};

export default WaitListActions;
