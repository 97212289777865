import { useEffect, useState } from 'react';
import { Tooltip } from 'components/Base/Tooltip';

export const CopyTextButton = ({
  title = '',
}: {
  title: string | undefined;
}) => {
  const [tooltipLabel, setTooltipLabel] = useState<string>('Copy');

  useEffect(() => {
    if (tooltipLabel === 'Copied') {
      setTimeout(() => {
        setTooltipLabel('Copy');
      }, 3000);
    }
  }, [tooltipLabel]);

  return (
    <Tooltip title={tooltipLabel}>
      <button
        type="button"
        className="button-transparent"
        onClick={() => {
          navigator.clipboard.writeText(title);
          setTooltipLabel('Copied');
        }}
      >
        {title}
      </button>
    </Tooltip>
  );
};
